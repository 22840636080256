import React, { useState } from "react";
import GuestFooter from "./GuestFooter";
import GuestHeader from "./GuestHeader";







const GuestCandle = () => {
    const [isFlameOn, setIsFlameOn] = useState(true); // Manage flame state


    const toggleFlame = () => {
        setIsFlameOn((prev) => !prev);
    };

    return (
        <>
            <GuestHeader />
           

            <section className="candle_section">

                <div className="candle">
                    {/* The flame will fade in/out based on the isFlameOn state */}
                    <div className={`flame ${isFlameOn ? "flame-on wobble" : "flame-off"}`}>
                        <div className="shadows" />
                        <div className="top" />
                        <div className="middle" />
                        <div className="bottom" />
                    </div>

                    <div className={`wick ${isFlameOn ? "" : "smoke-on"}`} /> {/* Add smoke animation when the flame is off */}
                    <div className="wax" />
                </div>

                <audio src="https://api.morbaseliosjsocderby.org/prayer.ogg" autoPlay />
            </section>
            {/* <GuestFooter /> */}
        </>
    );
};

export default GuestCandle;
