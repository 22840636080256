import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';


const GuestOurOfficials = () => {

    const navigate = useNavigate();

    const [ourOffials, setOurOfficials] = useState([{

        bava_name: "His Holiness Moran Mor Ignatius Aphrem II",
        tag_name: "PATRIARCH OF ANTIOCH & ALL THE EAST",
        bava_imageurl: "https://www.bsmcathedral.org/assets/images/patriarch-aphrem-ii-384x480.png",
        bava_main_details: "Born in Kamishly, Syria on May 3, 1965, the youngest son of Mr. & Mrs. Issa Karim, His Eminence Metropolitan Mor Cyril Aphrem Karim lost his father at an early age and was raised ,with the rest of his family, by his late and loving mother Khanema. He entered St. Ephrem’s Theological Seminary in Atchaneh, Lebanon in 1977, following his primary schooling in Kamishly. In 1982, His Eminence served the Syriac Orthodox Archdiocese of Aleppo, Syria, for the next two years. From 1984 to 1988, Archbishop Karim pursued higher studies at the Coptic Theological Seminary in Cairo, Egypt, graduating with a Bachelor Degree of Divinity.",
        bava_more_details: [
          "In 1985, Metropolitan Karim took the vows of a monk, and put himself in the service of the Church. He was ordained to the diaconate in Egypt, and later the same year, to the sacred priesthood in Kamishly. From 1988 to 1989, Mor Cyril served as both, the secretary of His Holiness, Moran Mor Zakka I Iwas, Syrian Orhtodox Patriarch of Antioch and all the East, and as a teacher at St. Ephrem’s Theological Seminary in Damascus, Syria. In 1991, His Eminence entered St. Patrick’s College in Maynooth, Ireland, where he received a License of Sacred Theology Degree, in 1992, and his Doctor of Divinity Degree in 1994, following a defense of his doctoral thesis on The Symbolism of the cross in early Syriac Christianity.",
          "On Sunday, January 28, 1996, His Eminence was consecrated as Metropolitan and Patriarchal Vicar to the Archdiocese of the Syriac Orthodox Church for the Eastern United States, by His Holiness Patriarch Zakka I Iwas, at St. Mary ‘s Syriac Orthodox Church in Kamishly. Archbishop Mor Cyril Aphrem Karim arrived in The United States on March 2, 1996, and was officially installed to his position at St. Mark’s Cathedral in Teaneck, New Jersey.",
          "On March 21, 2014, His Holiness Patriarch Zakka I Iwas was called into his heavenly abode. Subsequently, the Holy Synod elected His Eminence Metropolitan Mor Cyril Aphrem Karim as the successor to the Holy Throne and the supreme head of the universal Syrian orthodox church. On May 29, 2014, in Damascus, Syria, His Eminence was consecrated as the 123rdsuccessor of St. Peter, with the name His Holiness Patriarch Ignatius Aphrem II."
        ]
      },
      {
    
        bava_name: "His Beatitude Aboon Mor Baselious Thomas I",
        tag_name: "Catholicose of India",
        bava_imageurl: "https://upload.wikimedia.org/wikipedia/commons/5/59/H.B._Mor_Baselios_Thomas_I.jpg",
        bava_main_details: "Catholicos Aboon Mor Baselios Thomas-I,the spiritual head of the Syrian Christians of India,was born in the Cheruvillil family of Vadayambadi, Puthenkuriz to Mathai and Kunjamma on 22nd July 1929.His priestly formation was under the guidance of Mor Philoxenos Paulose (late Catholicos Mor Baselios Paulose-II) who ordained him ‘Korooyo’ in 1952 and ‘Shamshono’ at Kadamattom Church in 1957. In August 1958, C M Thomas was ordained ‘Kassisso’ (full priest) by Mor Yulius Elias at the Majanikkara Dayro.",
        bava_more_details: [
          "In 1959 he was appointed as the vicar of St.Peter’s church, Puthencuriz. Later he served the churches at Vellathooval, Keezhumuri, Fort Cochin, Valamboor, Calcutta and Thrissur. For a period of seven years from 1967, he was the organizing secretary of Kolenchery Medical Mission Hospital. He also served as the Chief organizer of the North Indian mission at Bhilai in 1970’s and as the secretary of the Pourasthaya Suvishesha Samajam in 1974. He was well known as a great revivalist preacher and was also recognized as an excellent organizer.",
          "In January 1974, Fr. C M Thomas was elected to the episcopate by the Malankara Jacobite Syrian Christian Association convened at the Karingachira St. George Church and was consecrated Metropolitan with the title ‘Mor Dionysius’ by H.H. Mor Ignatius Ya`qub-III on 24th February 1974 at Damascus, Syria. He was entrusted with the charge of Angamali diocese, the largest of all the Syrian Orthodox dioceses which has a membership of more than half a million.",
          "On Friday the 26th July 2002, His Holiness Moran Mor Ignatius Zakka-I Iwas the Patriarch of Antioch & all the East, consecrated the 73-year old Mor Dionysius Thomas as the CATHOLICOS with the title ‘BASELIOS THOMAS I’ at a solemn function held at the St. Peter & St. Paul Cathedral in Mor Ephrem Monastery, Ma`arat Sayyidnaya, near Damascus.",
          "In the Syrian Orthodox Church hierarchy, the Catholicos is second in rank to the supreme spiritual head, the Patriarch of Antioch."
        ]
      },
      {
    
        bava_name: "His Grace Issac Mor Osthatheos",
        tag_name: "UK Diocese - Metropolitan",
        bava_imageurl: "https://www.bsmcathedral.org/assets/images/26-384x480.png",
        bava_main_details: "H.G. Mor Osthatheos Ishaq was born on January 17th 1976 as the son of Mr. C.I. Thomas and Saramma Thomas, Pachilakkattu (Ceetharukuzhiyil) Puthuveli, Kottayam. The St. Stephens Jacobite Syrian Orthodox Church, Koothattukulam in Kandanadu Diocese is the home parish of the Metropolitan. Late Lamented Mor Osthathios Thomas, Metropolitan of Kochi diocese, is his grandfather’s brother.",
        bava_more_details: [
          "From a very young age itself he was greatly involved in religious affairs. It was at the age of seven, that he came to Kyomtha Seminary, Thiuvankulam with his grand uncle Mor Osthathios Thomas. He completed primary and secondary school education from Georgian Academy English Medium School, Thiruvankulam and high school from RMHS Vadavucode. Later he attained graduation in Economics from St. Alberts College, Ernakulam and Post-graduation from Kerala University. He also graduated in Library Science from Indira Gandhi National Open University, New Delhi.",
          "He was ordained a Sub-Deacon (Kooryoo) by late lamented Mor Osthatheos Thomas Metropolitan on 13th May 1993 and the Fifth order of Priesthood (Auyfatheachno) by His Grace Mor Gregorios Joseph, the present Metropolitan of Kochi diocese. He served as the secretary of the Metropolitans Their Graces Late Lamented Mor Osthathios Thomas and Mor Gregorios. After completing secular education, he graduated in theology from M.S.O.T. Seminary, Udayagiri, Vettikkal. Later on he took diploma in, Syriac language and Liturgy, from the St. Aphrem Theological Seminary, Damascus, Syria.",
          "During this period he served as the secretary to His Holiness Moran Mor Ignatius Zakka-I Iwas, the Patriarch of Antioch and all the East. During the third Apostolic visit of His Holiness to India in 2004, Deacon Saji, as the Metropolitan was known earlier, accompanied His Holiness as the secretary of His Holiness for the church affairs in India. Later he continued the Syriac studies in Kurkumo Dayro, Mardin, Turkey. During these days he had opportunity to visit almost all the Monasteries in Turkey.",
          "He was ordained a full Deacon (Samshoono) by His Grace Mor Athanasious Geevarghese, the former secretary to His Holiness The Patriarch. On 12th November 2006 His Holiness Moran Mor Ignatius Zakka-I Iwas, the Prince Patriarch of Antioch and all the East ordained him a priest (Kassisso) at the St. Peter's & St. Paul's Cathedral in Damascus. Later he served as the Vicar of St. George Jacobite Syrian Cathedral, Karingachira, Ernakulam. At the time of his Metropolitan consecration he was serving the Suryoyo parishes under His Eminence Mor Koorilose Aphrem Karim, the Archbishop of The Eastern United States. Currently he is pursuing his Masters in Sacramental Theology at Holy Cross Greek Orthodox Theological Seminary, Boston, Massachusetts USA.",
          "Following his selection to high priesthood in December 2009 by the Malankara Episcopal synod and the respective general bodies of the church in India, his name was forwarded to the Supreme head of the Universal Syriac Orthodox Church, the Patriarch of Antioch & All the East, which was eventually approved by His Holiness. On 2nd January 2010 he was consecrated a monk (Ramban) along with Fr. Zachraia Therambil by names Aphroth and Sabor respectively by His Beatitude the Catholicos.",
          "On January4, 2010, His Beatitude the Catholicos Baselios Thomas-I, assisted by metropolitans from India, consecrated him a Metropolitan by name OSTHATHEOS at a divine ceremony held at the St. Athanasious Cathedral in the Malankara Syrian Church headquarters, Puthencuriz. On ordination the new metropolitan was given charge of the Mylapore diocese which was earlier known as Chennai diocese. Mor Philoxinos Zacharias Therambil was also ordained a Metropolitan, for the Malabar diocese, along with Mor Osthathios during the same ceremony.",
          "On January 23, 2010, Mor Osthatheos Ishaq along with Mor Philoxinos Zacharias visited the Patriarchate in Damascus and submitted Salmoosa (obedience in writing) to His Holiness following which The Patriarch issued Susthathikkon to the new metropolitans. Later they also visited the Holy city of Jerusalem before returning to India.",
          "The Sunthroniso (the official ceremony of assuming the diocesan charge) of Mor Osthatheos Ishaq was held on Sunday the 21st February 2010 at the St. Thomas church in Annanagar, Chennai. The holy ceremony was led by His Beatitude the Catholicos Mor Baselios Thomas-I, the visiting Suryoyo Metropolitans Mor Dionsyius Behanan Jajawi (retd.), Mor Osthatheos Matta Roham (Jazirah & Euphrates) and other Metropolitans of the Malankara Church."
        ]
      }
    ])

    const onBavaClick = (index)=>{
        navigate('/spritualheads', { state: { index } });
    }

    const renderOurBavas = () => {

        return Object.entries(ourOffials).map((([i, data]) => {

            return <div className="col-lg-6 col-xl-4" onClick={()=>{onBavaClick(i)}}>
                <div className="card leaders_card mb-5 mb-xl-0" style={{cursor:'pointer'}}>
                    <div className="card-body leaders_card-body leaders">
                        <img src={data.bava_imageurl} className="card-img-top" alt="..." />

                        <div className="small text-uppercase text-muted">
                            {data.tag_name}
                        </div>
                        <div className="mb-1">
                            <span className="text-center text-uppercase">{data.bava_name}</span>
                        </div>

                        <div className='mb-1'>
                            <p className="fw-light" style={{float:'right'}}>read more</p>
                        </div>

                    </div>
                </div>
            </div>

        }))


    }


    return (<section className="">
        <div className="container px-5 my-5">
            <div className="text-center mb-5">
                <h1 className="fw-bolder">Our Officials</h1>
            </div>
            <div className="row gx-5 leaders_row justify-content-center">

                {renderOurBavas()}

            </div>
        </div>
    </section>)
}

export default GuestOurOfficials;